import Dropzone from "dropzone";
import { DirectUpload } from "@rails/activestorage";
import { getMetaValue, findElement, removeElement, insertAfter } from "helpers";
import { ValidationController } from "stimulus-validation";
var Honeybadger = require("honeybadger-js");

export default class extends ValidationController {
  static targets = ["dropzone", "input", "btn"];

  static rules = {
    street_address: { presence: { allowEmpty: false } },
    name: { presence: { allowEmpty: false } },
    phone: { presence: { allowEmpty: false } },
    email: { presence: { allowEmpty: false } },
    city: { presence: { allowEmpty: false } },
    zip_code: { presence: { allowEmpty: false } },
    price: { presence: { allowEmpty: false } },
    date_available: { presence: { allowEmpty: false } },
  };

  static validators = {
    validImages: { attributes: ["images"] },
  };

  connect() {
    Honeybadger.configure({
      apiKey: "8421b0c8",
    });
    super.connect();
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
    this.serverErrors = false;
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("removedfile", (file) => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", (file) => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("queuecomplete", () => {
      if (this.serverErrors) {
        this.displayServerErrorMessages();
        this.removeSpinner();
      } else {
        window.location = "/uploads/complete";
      }
    });

    this.dropZone.on("error", (file, errorMessage) => {
      this.serverErrors = [
        "A server error occurred. Please try again",
        errorMessage,
      ];
      this.removeSpinner();
      this.displayServerErrorMessages();
      this.resetFiles();
    });

    this.dropZone.on("sendingmultiple", (data, xhr, formData) => {
      $(this.element)
        .find(":input")
        .each(function () {
          let name = $(this).attr("name");
          let val;
          if ($(this).is(":checkbox")) {
            val = $(this).is(":checked");
          } else {
            val = $(this).val();
          }
          formData.append(name, val);
        });
    });

    this.dropZone.on("successmultiple", (file, response) => {
      // this method will indicate success of uploading the files, but not necessarily
      // of creating the upload object in the Rails controller.
      // if for some reason client-side validation does not work and the upload is invalid,
      // use this method for getting the server response and displaying errors
      if (response.success === false) {
        this.serverErrors = response.errors;
        this.displayServerErrorMessages();
        this.resetFiles();
        this.removeSpinner();
      } else {
        return;
      }
    });
  }

  resetFiles() {
    for (const file of this.dropZone.files) {
      file.status = Dropzone.QUEUED;
    }
  }

  displayServerErrorMessages() {
    var errorString = "";
    for (var errorMessage of this.serverErrors) {
      errorString = errorString + "<li><p>" + errorMessage + "</p></li>";
    }
    $("#error_explanation").html(
      '<div class="alert alert-danger"><ul>' + errorString + "</ul></div>"
    );
  }

  processQueue(e) {
    // start with clean slate
    this.serverErrors = false;
    event.preventDefault();
    event.stopPropagation();
    this.validateAll(e);
    if (!this.errors.hasAny()) {
      this.addSpinner();
      this.dropZone.processQueue();
    }
  }

  afterValidate({ el, attr }) {
    const errorEl = this.errorMessageEl(el);
    errorEl.textContent = this.errorMessage(attr);
    if (this.errors.has(attr)) {
      $(errorEl).addClass("alert alert-danger");
    } else {
      $(errorEl).removeClass("alert alert-danger");
    }
  }

  errorMessageEl(el) {
    return el.closest(".form-group").querySelector(".error-message");
  }

  errorMessage(attr) {
    return this.errors.has(attr) ? this.errors.get(attr)[0] : "";
  }

  validImages({ attr, value }) {
    if (this.dropZone.files.length <= 0) {
      this.errors.add(attr, "Please attach at least one image");
    }
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-url");
  }

  get maxFiles() {
    return this.data.get("maxFiles") || 10;
  }

  get maxFileSize() {
    return this.data.get("maxFileSize") || 512;
  }

  get acceptedFiles() {
    return this.data.get("acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  addSpinner() {
    $(this.btnTarget).prop("disabled", true);
    $(this.btnTarget).html(
      `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Uploading...`
    );
  }

  removeSpinner() {
    $(this.btnTarget).prop("disabled", false);
    $(this.btnTarget).html("Upload Images");
  }
}

class DirectUploadController {
  constructor(source, file) {
    this.directUpload = createDirectUpload(file, source.url, this);
    this.source = source;
    this.file = file;
  }

  start() {
    this.file.controller = this;
    this.hiddenInput = this.createHiddenInput();
    this.directUpload.create((error, attributes) => {
      if (error) {
        removeElement(this.hiddenInput);
        this.emitDropzoneError(error);
      } else {
        this.hiddenInput.value = attributes.signed_id;
        this.emitDropzoneSuccess();
      }
    });
  }

  createHiddenInput() {
    const input = document.createElement("input");
    input.type = "hidden";
    input.name = this.source.inputTarget.name;
    insertAfter(input, this.source.inputTarget);
    return input;
  }

  directUploadWillStoreFileWithXHR(xhr) {
    this.bindProgressEvent(xhr);
    this.emitDropzoneUploading();
  }

  bindProgressEvent(xhr) {
    this.xhr = xhr;
    this.xhr.upload.addEventListener("progress", (event) =>
      this.uploadRequestDidProgress(event)
    );
  }

  uploadRequestDidProgress(event) {
    const element = this.source.element;
    const progress = (event.loaded / event.total) * 100;
    findElement(
      this.file.previewTemplate,
      ".dz-upload"
    ).style.width = `${progress}%`;
  }

  emitDropzoneUploading() {
    this.file.status = Dropzone.UPLOADING;
    this.source.dropZone.emit("processing", this.file);
  }

  emitDropzoneError(error) {
    this.file.status = Dropzone.ERROR;
    this.source.dropZone.emit("error", this.file, error);
    this.source.dropZone.emit("complete", this.file);
  }

  emitDropzoneSuccess() {
    this.file.status = Dropzone.SUCCESS;
    this.source.dropZone.emit("success", this.file);
    this.source.dropZone.emit("complete", this.file);
  }
}

function createDirectUploadController(source, file) {
  return new DirectUploadController(source, file);
}

function createDirectUpload(file, url, controller) {
  return new DirectUpload(file, url, controller);
}

function createDropZone(controller) {
  return new Dropzone(controller.dropzoneTarget, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
    autoProcessQueue: false,
    parallelUploads: controller.maxFiles,
    uploadMultiple: true,
    timeout: 180000,
  });
}
